import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
  Contact,
} from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";
import "../style/catering.css";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null} className={"catering-page-container"}>
          <Grid
            stackable={true}
            className={"component-section-container catering-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={10}>
              <h1>Special Catering</h1>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    displayLabel: "Address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "guest",
                    displayLabel: "Number Of Guests",
                    placeholder: "Number Of Guests",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "delivery",
                    displayLabel: "Delivery",
                    type: "select",
                    options: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                  },
                  {
                    label: "privateEventAtVenue",
                    displayLabel:
                      "Request Private Event at our venue (Tiki Pete)",
                    type: "select",
                    options: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                  },
                  {
                    label: "cateringType",
                    displayLabel: "Catering Type",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Popup", value: "Popup" },
                      {
                        label: "Group Ordering - Individual Packaging",
                        value: "Group Ordering - Individual Packaging",
                      },
                      { label: "Buffet Style", value: "Buffet Style" },
                    ],
                  },
                  {
                    label: "eventDate",
                    displayLabel: "Event Date",
                    placeholder: "Event Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "startTime",
                    displayLabel: "Start Time",
                    placeholder: "Start Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "endTime",
                    displayLabel: "End Time",
                    placeholder: "End Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "referenceSource",
                    displayLabel: "How did you hear about us ?",
                    type: "select",
                    required: true,
                    options: [
                      {
                        label: "Saw us at a food festival or event",
                        value: "Saw us at a food festival or event",
                      },
                      {
                        label: "Visited our popup location at tiki pete",
                        value: "Visited our popup location at tiki pete",
                      },
                      { label: "Instagram", value: "Instagram" },
                      { label: "Yelp", value: "Yelp" },
                      { label: "Word Of Mouth", value: "Word Of Mouth" },
                      { label: "Other", value: "Other" },
                    ],
                  },
                  {
                    label: "otherReferenceSource",
                    displayLabel:
                      "If other please let us know how did you hear from us",
                    placeholder:
                      "If other please let us know how did you hear from us",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "message",
                    placeholder: "Additional Message",
                    type: "textarea",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
            <Grid.Column width={16} className={"contact-map-section-container"}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                email={fishermanBusiness.primaryLocation.email}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            verticalAlign={"middle"}
            textAlign={"top"}
            centered={true}
            column={"2"}
            className={"additional-infos component-section-container"}
          >
            <Grid.Row stretched={true}>
              <Grid.Column width={5} textAlign={"center"}>
                <div>
                  <iframe
                    src={"https://player.vimeo.com/video/842639528?controls=1"}
                    height={"350"}
                    frameborder={"0"}
                  />
                  <h3>Popup</h3>
                  <p>
                    1-2 Bun Me Up chefs that will setup, serve, and cleanup
                    onsite at your location, whether it’s a corporate office,
                    house party, or outdoor bbq picnic setting. Popular for
                    large group events where we do all the work and you enjoy,
                    best for BYOB (Build Your Own Bun)!
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column width={5} textAlign={"center"}>
                <div>
                  <div>
                    <Image
                      src={
                        "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/360151167_268067332512350_4968266280429794117_n.jpg"
                      }
                      centered={true}
                      size={"medium"}
                    />
                  </div>
                  <h3>Group Ordering - Individual Packaging</h3>
                  <p>
                    Individually packaged meals for your team, family, or
                    friends. Popular for corporate offices where people need to
                    grab their food and go to their meetings! Containers are
                    microwaveable in case you need to take it home!
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column width={5} textAlign={"center"}>
                <div>
                  <div>
                    <Image
                      src={
                        "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/Party_Buffet_Catering.jpg"
                      }
                      centered={true}
                      size={"medium"}
                    />
                  </div>
                  <h3>Party Buffet</h3>
                  <p>
                    All sorts of platter combinations from our buns to our
                    proteins, rice, and sides artfully arranged. Great for
                    birthdays, weddings, or even happy hour at the office!
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
        }
        componentIdentifier
      }
    }
  }
`;
